<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="userProfile.highlights"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Highlights</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="copyHighlight(item)">assignment</v-icon>
        <v-icon small @click="deleteHighlight(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{
      response
    }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { db } from "../../firebaseDb";
//const fb = require('../../firebaseDb.js')

export default {
  data() {
    return {
      snackbar: false,
      snackColor: "",
      response: "",
      headers: [
        {
          text: "G.R. No.",
          align: "start",
          value: "caseId",
        },
        { text: "Name", value: "title" },
        { text: "Date", value: "date" },
        { text: "Items", value: "texts.length" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState(["currentUser", "userProfile"]),
  },
  methods: {
    printOut(item) {
      console.log(item);
    },
    copyHighlight(item) {
      // create a div container for our copy
      let container = document.createElement("div");

      // hide the container. required for copying to clipboard
      container.style.position = "fixed";
      container.style.pointerEvents = "none";
      container.style.opacity = 0;

      // create the innerHTML to house the text from the database
      let textFrag = new DocumentFragment();

      // cycle through the texts
      this.userProfile.highlights[this.getIndex(item)].texts.forEach((val) => {
        textFrag.appendChild(
          document.createRange().createContextualFragment(val)
        );
        textFrag.appendChild(document.createElement("br"));
      });
      console.log(textFrag);
      // create the citation and append to the text
      let citationText = document.createTextNode(
        item.grno + " " + item.date.seconds + " " + item.title
      );
      let para = document.createElement("p");
      para.appendChild(citationText);
      textFrag.appendChild(document.createElement("hr"));
      textFrag.appendChild(citationText);

      // put selected data with citation to container
      container.appendChild(textFrag);

      // the following are needed to put data to the clipboard
      // add the newly created HTML to the webpage
      document.body.appendChild(container);

      // reset the highlighted text to use the new HTML for copying
      window.getSelection().removeAllRanges();
      let newRange = document.createRange();
      newRange.selectNode(container);
      window.getSelection().addRange(newRange);

      // copies text to clipboard
      document.execCommand("copy");
      console.log(item);
      this.snackbar = true;
      this.snackColor = "success";
      this.response = "Highlight has been copied to clipboard!";
    },
    deleteHighlight(item) {
      this.userProfile.highlights.splice(this.getIndex(item), 1);
      this.saveHighlights();
    },
    saveHighlights() {
      let dbRef = db.collection("users").doc(this.currentUser.uid);
      dbRef
        .update({ ...this.userProfile })
        .then(() => {
          this.snackbar = true;
          this.snackColor = "success";
          this.response = "Highlight has been removed!";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.snackColor = "fail";
          this.response = error;
        });
    },
    getIndex(item) {
      return this.userProfile.highlights.findIndex((highlight) => {
        if (highlight.grno == item.grno) {
          return true;
        }
      });
    },
    // deleteUser(id){
    //   if (window.confirm("Do you really want to delete?")) {
    //     fb.collection("users").doc(id).delete().then(() => {
    //         console.log("Document deleted!");
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     })
    //   }
    // }
  },
};
</script>
